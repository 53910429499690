<script>
import { Button, Tabs } from 'ant-design-vue'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      username: '',
      password: '',
      phoneNumber: '',
      getCodeMsg: '发送验证码',
      countdown: 60,
      code: '',
      showEye: true,
      formType: 'form',
    }
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'GetCode', 'LoginByCode', 'GetCodeFind', 'UpdatePwd']),
    renderHeader() {
      return (
        <div class="login-header">
          <img src={require('./asset/header.png')} />
        </div>
      )
    },
    renderImg() {
      return <div class="login-img" />
    },
    loginSuccess(userData) {
      if (this.$route.query.redirect) {
        window.location.href = this.$route.query.redirect + '?token=' + localStorage.getItem('CX-Access-Token')
      } else {
        window.location.href = '/index'
      }
    },
    onLogin() {
      if (this.username === '' || this.password === '') {
        this.$message.error('请输入账号/密码')
        return
      }
      this.Login({
        username: this.username,
        password: this.password,
      })
        .then((result) => {
          this.loginSuccess(result)
        })
        .catch(() => this.$message.error('账号或密码错误'))
        .finally(() => {
          // state.loginBtn = false
        })
    },
    // 重置
    resetValue() {
      this.getCodeMsg = '发送验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
      this.timeOut = ''
      this.username = ''
      this.password = ''
      this.phoneNumber = ''
      this.code = ''
      this.formType = 'form'
    },
    // 找回手机确认
    onNext() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.UpdatePwd({
        code: this.code,
        password: this.password,
        phone: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.resetValue()
          this.isShow = 'login-form-item'
          this.$message.success('修改密码成功')
          this.loginWayFindPass = !this.loginWayFindPass
          this.loginWay = !this.loginWay
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    // 验证码登录按钮
    onLoginByCode() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.LoginByCode({
        phoneNumber: this.phoneNumber,
        code: this.code,
      }).then((e) => {
        if (e.access_token) {
          this.loginSuccess(e)
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    renderLoginUserName() {
      return (
        <div class="login-username">
          <div>
            <input placeholder={'账号/手机号码'} v-model={this.username} />
          </div>
          <div>
            <input placeholder={'密码'} type={this.showEye ? 'password' : 'text'} v-model={this.password} />
            <img src={require('./asset/eye.png')} onClick={() => (this.showEye = !this.showEye)} />
          </div>
          <div onClick={this.changeFormType}>忘记密码</div>
          <Button style={{ marginTop: '70px', height: '48px' }} type="primary" onClick={this.onLogin}>
            登录
          </Button>
        </div>
      )
    },
    getCodeFind() {
      if (!this.phoneNumber) {
        this.$message.error('请输入手机号码')
        return
      }
      this.GetCodeFind({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    getCode() {
      if (!this.phoneNumber) {
        this.$message.error('请输入手机号码')
        return
      }
      this.GetCode({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    setCountdown() {
      if (this.countdown === 0) {
        this.getCodeNow()
      } else {
        this.getCodeMsg = this.countdown + 's'
        this.getDisabled = true
        this.countdown--
      }
    },
    getCodeNow() {
      this.getCodeMsg = '发送验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
    },
    changeFormType() {
      this.formType = 'forgot'
      clearInterval(this.timeOut)
      this.countdown = 60
      this.getCodeMsg = '发送验证码'
    },
    renderLoginPhone() {
      return (
        <div class="login-username">
          <div>
            <input placeholder={'手机号码'} v-model={this.phoneNumber} />
          </div>
          <div>
            <input placeholder={'验证码'} type={this.showEye ? 'password' : 'text'} v-model={this.code} />
            <a onClick={this.getCode}>{this.getCodeMsg}</a>
          </div>
          <div onClick={this.changeFormType}>忘记密码</div>
          <Button style={{ marginTop: '70px', height: '48px' }} type="primary" onClick={this.onLoginByCode}>
            登录
          </Button>
        </div>
      )
    },
    renderForm() {
      const data = [
        {
          name: '账号登录',
          view: this.renderLoginUserName,
        },
        {
          name: '手机登录',
          view: this.renderLoginPhone,
        },
      ]
      return (
        <div class="login-form">
          <Tabs>
            {data.map((item) => {
              return (
                <Tabs.TabPane tab={item.name} key={item.name}>
                  {item.view()}
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </div>
      )
    },
    renderForgotPassword() {
      return (
        <div class="login-username forgot-password login-form">
          <span>设置新密码</span>
          <div>
            <input placeholder={'手机号码'} v-model={this.phoneNumber} />
          </div>
          <div>
            <input placeholder={'验证码'} type={this.showEye ? 'password' : 'text'} v-model={this.code} />
            <a onClick={this.getCodeFind}>{this.getCodeMsg}</a>
          </div>
          <div>
            <input placeholder={'密码'} type={this.showEye ? 'password' : 'text'} v-model={this.password} />
            <img src={require('./asset/eye.png')} onClick={() => (this.showEye = !this.showEye)} />
          </div>
          <Button style={{ marginTop: '70px', height: '48px' }} type="primary" onClick={this.onNext}>
            修改密码
          </Button>
        </div>
      )
    },
    renderBody() {
      return (
        <div class="login-body">
          {this.renderImg()}
          {this.formType !== 'forgot' ? this.renderForm() : this.renderForgotPassword()}
        </div>
      )
    },
    renderFoot() {
      return (
        <div class="login-foot">
          <span>技术支持：浙江中兴慧农信息科技有限公司</span>
        </div>
      )
    },
  },
  render() {
    return (
      <div class="login-main">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFoot()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.login-main {
  display: flex;
  flex-direction: column;
  // width: 100%;
  width: calc(100vw);
  height: 100%;
}
.login-header {
  height: 100px;
  display: flex;
  align-items: center;
  width: calc(100vw);
  background-color: white;
  justify-content: center;
  // padding-left: 361px;
  img {
    width: 561px;
    height: 62px;
  }
}
.login-body {
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(149, 216, 211);
}
.login-foot {
  height: 100px;
  width: 100%;
  background: #0d8496;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
}
.login-img {
  width: 592px;
  height: 559px;
  background-image: url('./asset/body-img.png');
  background-size: 100% 100%;
  margin-top: 90px;
}
.login-form {
  width: 460px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 0px solid #ffffff;
  margin-left: 70px;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 30px;
  /deep/.ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  /deep/.ant-tabs-bar {
    border: none;
  }
}
.login-username {
  display: flex;
  flex-direction: column;
  div {
    img {
      width: 24px;
      height: 18px;
      cursor: pointer;
    }
    a {
      white-space: nowrap;
    }
  }
  div:nth-of-type(-n + 2) {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }
  div:nth-of-type(3) {
    margin-top: 22px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #4d4d4d;
    cursor: pointer;
  }
  div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #b3b3b3;
  }
  input {
    border: none;
    outline: none;
    width: calc(100% - 20px);
    font-size: 20px;
    &::placeholder {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      color: #999999;
    }
  }
  button {
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
.forgot-password {
  span:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4d4d4d;
  }
  div:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }
}
/deep/.ant-tabs-tab {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  color: #4d4d4d;
}
/deep/.ant-tabs-tab-active {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #1e92ff;
}
</style>
